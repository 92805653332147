import React from "react";

const ResearchReferences = () => {
  // Example data structure for your references.
  // Each entry can have:
  // - category: e.g., "Wallet Creation & Key Management"
  // - references: an array of research articles, each with url, title, authors, date, etc.
  const referenceData = [
    {
      category: "Wallet Creation & Key Management",
      references: [
        {
          title: "Practical Key-Extraction Attacks in Leading MPC Wallets",
          authors: "Nikolaos Makriyannis, Oren Yomtov, Arik Galansky",
          date: "January 29, 2024",
          link: "https://eprint.iacr.org/2023/1234.pdf",
        },
      ],
    },
    {
      category: "Cryptography Methods",
      references: [
        // Future references go here
      ],
    },
    {
      category: "Language Comparisons & Security",
      references: [
        // Future references go here
      ],
    },
    {
      category: "CVS Scores",
      references: [
        // Future references go here
      ],
    },
    {
      category: "Swaps & Pricing Mechanisms",
      references: [
        // Future references go here
      ],
    },
    {
      category: "Other DeFi-Stack Research",
      references: [
        // Future references go here
      ],
    },
  ];

  return (
    <div className="wrapper">
      <h1 style={{ marginTop: "20px", color: "lightblue" }}>
        Research References
      </h1>
      <p style={{ marginBottom: "30px", color: "#f2f7f7" }}>
        Below are the research articles we reference for learning about various
        layers and needs within the DeFi stack. This page will expand as we add
        more categories and references.
      </p>

      {referenceData.map((categoryItem, idx) => (
        <div key={idx} className="research-category-container">
          <h2 style={{ color: "#00bfff" }}>{categoryItem.category}</h2>
          {categoryItem.references.length === 0 ? (
            <p style={{ color: "#ccc" }}>No references yet in this category.</p>
          ) : (
            categoryItem.references.map((ref, refIdx) => (
              <div key={refIdx} className="research-reference-item">
                <h3 style={{ color: "#e8ecf1" }}>{ref.title}</h3>
                <p style={{ fontSize: "0.9em", color: "#f2f7f7" }}>
                  <strong>Authors:</strong> {ref.authors}
                </p>
                <p style={{ fontSize: "0.9em", color: "#f2f7f7" }}>
                  <strong>Date:</strong> {ref.date}
                </p>
                <a
                  href={ref.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#00bfff" }}
                >
                  View Paper
                </a>
              </div>
            ))
          )}
        </div>
      ))}
    </div>
  );
};

export default ResearchReferences;
