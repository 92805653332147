import React from "react";
import "../App.css";

const Perishability = () => {
  return (
    <div className="intro-container">
      <h1>Protocol Perishability Factor</h1>
      <p>
        Welcome to the Protocol Perishability Dashboard—a robust, data-driven
        tool designed to evaluate on-chain activities and highlight the
        potential risk of loss of funds. This encompasses a broad range of
        threats, including genuine LP removals, rug pulls, exploits, and insider
        threats.
      </p>
      <p>
        By combining an actuarial perspective with detailed data analysis of the
        underlying technical code, the Protocol Perishability Factor provides
        insights into both the short-term health and long-term sustainability of
        various Web3 protocols. Our goal is to help you make well-informed
        decisions about where to allocate resources and how to navigate the
        evolving decentralized finance landscape.
      </p>
    </div>
  );
};

export default Perishability;
