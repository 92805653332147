import React from "react";

const CCESItinerary = () => {
  return (
    <div
      // Use the same wrapper class you've used in other pages
      className="wrapper"
      style={{
        maxWidth: "70vw",
        margin: "30px auto",
        textAlign: "left", // Left-align for easier reading, adjust as desired
        padding: "20px",
      }}
    >
      {/* Main Title */}
      <h1
        style={{
          color: "lightblue",
          textAlign: "center",
          marginBottom: "40px",
        }}
      >
        Certified Cryptocurrency Exploit Specialist (CCES) Itinerary
      </h1>

      {/* Intro Paragraph */}
      <p
        style={{
          color: "#f2f7f7",
          fontSize: "1.2em",
          marginBottom: "20px",
          lineHeight: "1.6",
        }}
      >
        The <strong>Certified Cryptocurrency Exploit Specialist (CCES)</strong>{" "}
        certification equips professionals with the investigative expertise to
        analyze on-chain data, identify exploits, and support claims
        adjudication in the crypto space. While these specialists function
        similarly to claims adjusters, they primarily focus on deep, on-chain
        investigations to assess the nature of exploits, measure potential
        losses, and document risk patterns.
      </p>

      {/* Chapter 1 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 1: Foundations of On-Chain Investigations
        </h2>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Blockchain Basics:</strong> Understanding block creation,
            consensus mechanisms (PoW, PoS), transaction flow.
          </li>
          <li>
            <strong>Key Terms &amp; Concepts:</strong> Gas, transaction fees,
            mempool, transaction lifecycle.
          </li>
          <li>
            <strong>Addresses &amp; Wallets:</strong> EOA (Externally Owned
            Accounts) vs. smart contract addresses, single-sig vs. multi-sig
            structures, cold vs. hot wallets.
          </li>
          <li>
            <strong>Cryptographic Fundamentals:</strong> Private/public keys,
            digital signatures, hashing.
          </li>
        </ul>
      </div>

      {/* Chapter 2 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 2: Crypto Exploits &amp; Attack Vectors
        </h2>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Exploit Taxonomy:</strong> Rug pulls, flash loan exploits,
            reentrancy attacks, phishing scams, price oracle manipulation.
          </li>
          <li>
            <strong>Real-World Case Studies:</strong> Detailed breakdowns of
            major breaches (The DAO, Mt. Gox, Poly Network).
          </li>
          <li>
            <strong>DeFi Vulnerabilities:</strong> Smart contract logic flaws,
            liquidity pool manipulation, yield farming exploit points.
          </li>
          <li>
            <strong>NFT &amp; Layer 2 Risks:</strong> Common pitfalls in NFT
            marketplaces, bridging vulnerabilities for L2 networks.
          </li>
        </ul>
      </div>

      {/* Chapter 3 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 3: Investigative Tools &amp; Techniques
        </h2>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Block Explorers:</strong> Mastering Etherscan, BscScan,
            Polygonscan; advanced search queries, decoding input data, tracking
            token flows.
          </li>
          <li>
            <strong>On-Chain Analytics Platforms:</strong> Nansen, Chainalysis,
            Dune Analytics; setting up queries, interpreting visualizations,
            identifying flagged addresses.
          </li>
          <li>
            <strong>Forensic Frameworks:</strong> Understanding wallet cluster
            analysis, chain hopping (bridges / cross-chain swaps), privacy tool
            usage (mixers, tumblers).
          </li>
          <li>
            <strong>Threat Intelligence &amp; OSINT:</strong> Using social
            media, forums, developer chats, and exploit announcements to gather
            evidence and contextual data.
          </li>
        </ul>
      </div>

      {/* Chapter 4 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 4: Data Gathering &amp; Evidence Preservation
        </h2>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Transaction Tracing:</strong> Identifying transaction
            patterns, wallet interactions, analyzing large-volume transfers.
          </li>
          <li>
            <strong>Exporting &amp; Documenting Data:</strong> CSV exports,
            screenshot captures, hashing evidence for tamper-proof records.
          </li>
          <li>
            <strong>Securing Confidential Info:</strong> Data encryption,
            offline storage, best practices for chain-of-custody handling.
          </li>
          <li>
            <strong>Compliance &amp; Legal Considerations:</strong> How to
            handle potential KYC/AML red flags, multi-jurisdictional issues,
            working with law enforcement or insurance carriers.
          </li>
        </ul>
      </div>

      {/* Chapter 5 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 5: Assessing Exploit Severity &amp; Claim Viability
        </h2>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Loss Valuation:</strong> Calculating stolen or lost assets,
            factoring market volatility, stablecoin vs. volatile tokens,
            cross-chain valuations.
          </li>
          <li>
            <strong>Liability Determination:</strong> Smart contract auditing
            context, developer negligence vs. user error, bug bounty and
            disclosure timing.
          </li>
          <li>
            <strong>Insurance Claims Workflow:</strong> Working with
            underwriters, actuaries, and insured parties to validate or dispute
            exploit-based claims.
          </li>
          <li>
            <strong>Fraud Detection:</strong> Identifying staged exploits or
            suspicious activity disguised as hacks (insider job, double-dipping
            claims, repeated suspicious addresses).
          </li>
        </ul>
      </div>

      {/* Chapter 6 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 6: Reporting &amp; Communicating Findings
        </h2>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Forensic Reporting Essentials:</strong> Structuring your
            report with executive summaries, technical details, exhibit logs.
          </li>
          <li>
            <strong>Effective Data Visualization:</strong> Charts, timelines,
            wallet flow diagrams, using third-party tools (GraphViz, Tableau).
          </li>
          <li>
            <strong>Presenting to Stakeholders:</strong> Bridging the gap
            between technical and non-technical audiences, risk mitigation
            proposals.
          </li>
          <li>
            <strong>Testifying &amp; Expert Witness Work:</strong> Guidelines
            for depositions, preparing for legal scrutiny, professional ethics.
          </li>
        </ul>
      </div>

      {/* Chapter 7 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 7: Future Trends &amp; Continuous Education
        </h2>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Emerging Networks &amp; Protocols:</strong> Rollups
            (Optimistic, ZK), sidechains, next-gen blockchains (Polkadot,
            Cosmos).
          </li>
          <li>
            <strong>Advanced Privacy Tools:</strong> Zero-knowledge proofs,
            Tornado Cash alternatives, decentralized identity (DID).
          </li>
          <li>
            <strong>Cross-Chain Security:</strong> Understanding bridging
            solutions, interoperability concerns, cross-chain exploit scenarios.
          </li>
          <li>
            <strong>Staying Up-to-Date:</strong> Ongoing research, security
            bulletins, exploit monitoring, professional communities.
          </li>
        </ul>
      </div>

      {/* NEW: Chapter 8 */}
      <div style={{ marginBottom: "40px" }}>
        <h2 style={{ color: "lightblue", marginBottom: "10px" }}>
          Chapter 8: Determining Exploit Cause &amp; Verifying Exploit Truth
        </h2>
        <p style={{ color: "#f2f7f7", lineHeight: "1.6" }}>
          In this chapter, we pinpoint the specific vulnerability type—whether
          it’s an arithmetic bug, access control oversight, or architectural
          logic flaw—and confirm the actual sequence of events that led to the
          exploit. Students learn to differentiate:
        </p>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Direct Cause:</strong> The immediate vulnerability attackers
            exploited (e.g.,{" "}
            <em>
              integer over/underflow, missing return value check, reentrancy
            </em>
            ) that directly enabled unauthorized transfers or logic
            manipulation.
          </li>
          <li>
            <strong>Indirect Cause:</strong> Contributing or secondary flaws
            (e.g.,{" "}
            <em>
              incorrect constructor name, uninitialized state variable,
              dangerous strict equality
            </em>
            ) that set the stage for or worsened the primary exploit.
          </li>
          <li>
            <strong>Environment &amp; Configuration Issues:</strong> External
            factors such as{" "}
            <em>timestamp dependence, default function visibility, oracles</em>,
            or chain-specific quirks that impacted contract safety.
          </li>
        </ul>
        <p style={{ color: "#f2f7f7", lineHeight: "1.6" }}>
          Detailed root cause analysis involves:
        </p>
        <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
          <li>
            <strong>Mapping to Known Exploit Categories:</strong> Identify
            whether the incident falls under <em>arithmetic bugs</em>,{" "}
            <em>access control vulnerabilities</em>, <em>price manipulation</em>
            ,<em>delegatecall misuse</em>, or <em>architectural logic flaws</em>
            .
          </li>
          <li>
            <strong>
              Using Automated Security Scanners (e.g. Slither, Mythril):
            </strong>{" "}
            Validate suspected vulnerabilities with static and dynamic analysis
            to confirm exploit feasibility—detecting{" "}
            <em>
              integer overflows, reentrancy, denial-of-service triggers, etc.
            </em>
          </li>
          <li>
            <strong>Tracing Attacker Steps:</strong> Reconstruct each
            transaction and function call invoked by the attacker to illustrate
            precisely how the bug was leveraged (e.g., repeated reentrancy
            calls, front-running a price update, or forcibly changing state via
            an unprotected function).
          </li>
          <li>
            <strong>Verifying Attack Authenticity:</strong> Cross-referencing
            on-chain data with off-chain intelligence—ensuring claims aren’t
            artificially manufactured or staged, and that the exploit truly
            occurred under the stated conditions.
          </li>
        </ul>
        <p style={{ color: "#f2f7f7", lineHeight: "1.6" }}>
          Students also learn to produce a comprehensive timeline of events,
          tying specific contract lines of code to the relevant exploit category
          and demonstrating the direct or indirect cause. This robust
          methodology ensures that CCES professionals can confidently confirm
          how and why an exploit happened—and whether the claim of exploitation
          aligns with the technical evidence.
        </p>
      </div>

      <hr style={{ margin: "40px 0", borderColor: "#444" }} />

      {/* Prerequisites */}
      <h2 style={{ color: "lightblue", marginBottom: "20px" }}>
        Certification Prerequisites
      </h2>
      <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
        <li>
          Foundational knowledge of blockchain technology and major crypto
          networks.
        </li>
        <li>
          Basic understanding of cryptographic concepts (hash functions,
          signatures).
        </li>
        <li>
          Familiarity with at least one blockchain explorer (Etherscan, BscScan,
          etc.).
        </li>
        <li>Ability to interpret and analyze large data sets.</li>
      </ul>

      {/* Exam & Practical Assessment */}
      <h2
        style={{
          color: "lightblue",
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        Examination &amp; Practical Assessment
      </h2>
      <ul style={{ lineHeight: "1.6", color: "#f2f7f7" }}>
        <li>
          <strong>Written Exam:</strong> Multiple-choice and short-answer
          questions covering exploit types, on-chain analytics, and relevant
          regulations.
        </li>
        <li>
          <strong>Practical Investigations:</strong> Students must conduct an
          on-chain investigation of a simulated exploit, produce a report
          detailing their process and conclusions, and pinpoint the direct and
          indirect causes.
        </li>
      </ul>

      <p
        style={{
          color: "#f2f7f7",
          fontSize: "1.2em",
          marginTop: "40px",
          lineHeight: "1.6",
        }}
      >
        By successfully completing this certification, professionals become
        prepared to handle intricate on-chain investigations, collaborate with
        underwriters and actuaries, and bolster overall confidence within the
        Web3 ecosystem.
      </p>
    </div>
  );
};

export default CCESItinerary;
